import { Directive, forwardRef, Renderer2,ElementRef, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR} from '@angular/forms';

const MASK_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MaskMoneyDirective),
  multi: true,
};
declare var $:any;

@Directive({
  selector: '[mask-money]',
  providers: [
    MASK_INPUT_CONTROL_VALUE_ACCESSOR,
  ]
})
export class MaskMoneyDirective implements OnInit,OnChanges{
  
  @Output("maskChange") maskChange = new EventEmitter();
  @Input("value") value:any = 0;
  @Input("setValueIn") setValueIn = new EventEmitter();

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef
  ) { 
    
  
  }
  setValue(value){
    this.maskChange.emit(this.formatValue(value));
  }
  /***
   * 
   * Seta o formato para o campo
   * 
   */
  setFormat(){
    
    let self = this;

    $(this.el.nativeElement).maskMoney({
      prefix:'R$ ', 
      allowNegative: false, 
      thousands:'.', 
      decimal:',', 
      affixesStay: true,
      formatOnBlur: true,
      selectAllOnFocus: true
    });
    $(this.el.nativeElement).on('change', function(event) {
      let value = self.unmask(event.target.value);
      self.setValue(value);
    });

  }
  unmask(value){

    return $(this.el.nativeElement).maskMoney("unmasked")[0];

  }
  setMask(){
    $(this.el.nativeElement).maskMoney("mask");
  }
  formatValue(value){
    return parseFloat(value).toFixed(2);
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(): void {
    
  }
  /**
   * 
   * Changes values
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes: any) {
    
    if(changes.value){
      this.value = this.formatValue(changes.value.currentValue);
      changes.value.currentValue = this.value;
      this.el.nativeElement.value = this.value;
      this.setFormat();
      this.setMask();
    }

  }
  

}
