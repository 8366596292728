import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartAddComponent } from 'src/app/components/sale/cart/cart-add/cart-add.component';
import { PartialModule } from '../partial/partial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../form/form.module';
import { CartStepsComponent } from 'src/app/components/sale/cart/cart-steps/cart-steps.component'; 
import { CartDateComponent } from 'src/app/components/sale/cart/cart-date/cart-date.component';
import { CartPassengerQtdComponent } from 'src/app/components/sale/cart/cart-passenger-qtd/cart-passenger-qtd.component';
import { ComboProductHotelsComponent } from 'src/app/components/sale/cart/combo/combo-product-hotels/combo-product-hotels.component';
import { CartTotalBtnComponent } from 'src/app/components/sale/cart/btn/cart-total-btn/cart-total-btn.component';
import { CartItemComponent } from '../../components/sale/cart/cart-item/cart-item.component';
import { CartDeleteBtnComponent } from 'src/app/components/sale/cart/btn/cart-delete-btn/cart-delete-btn.component';
import { CartShareBtnComponent } from '../../components/sale/cart/btn/cart-share-btn/cart-share-btn.component';
import { CartPrintBtnComponent } from '../../components/sale/cart/btn/cart-print-btn/cart-print-btn.component';
import { CartDownloadBtnComponent } from '../../components/sale/cart/btn/cart-download-btn/cart-download-btn.component';
import { CartPaymentBtnComponent } from '../../components/sale/cart/btn/cart-payment-btn/cart-payment-btn.component';
import { CartInvoiceBtnComponent } from '../../components/sale/cart/btn/cart-invoice-btn/cart-invoice-btn.component';
import { CartShareModalComponent } from '../../components/sale/cart/modal/cart-share-modal/cart-share-modal.component';
import { MatCardModule } from '@angular/material/card';
import { CartPaymentModalComponent } from '../../components/sale/cart/modal/cart-payment-modal/cart-payment-modal.component';
import { PaymentModule } from '../payment/payment.module';
import { CartAddSucessModalComponent } from '../../components/sale/cart/modal/cart-add-sucess-modal/cart-add-sucess-modal.component';
import { CartDeleteModalComponent } from '../../components/sale/cart/modal/cart-delete-modal/cart-delete-modal.component';
import { PipeModule } from '../pipe/pipe.module';
import { CartFlightDataComponent } from '../../components/sale/cart/cart-flight-data/cart-flight-data.component';
import { ComboAirportComponent } from '../../components/sale/cart/combo/combo-airport/combo-airport.component';
import { ComboAirlineComponent } from '../../components/sale/cart/combo/combo-airline/combo-airline.component';
import { DirectiveModule } from '../directive/directive.module';
import { CartNewBtnComponent } from '../../components/sale/cart/btn/cart-new-btn/cart-new-btn.component';
import { CartCloseQuoteBtnComponent } from '../../components/sale/cart/btn/cart-close-quote-btn/cart-close-quote-btn.component';

@NgModule({
  declarations: [  
    CartAddComponent,
    CartStepsComponent,
    CartDateComponent,
    CartPassengerQtdComponent,
    ComboProductHotelsComponent,
    CartTotalBtnComponent,
    CartItemComponent,
    CartDeleteBtnComponent,
    CartShareBtnComponent,
    CartPrintBtnComponent,
    CartDownloadBtnComponent,
    CartPaymentBtnComponent,
    CartInvoiceBtnComponent,
    CartShareModalComponent,
    CartPaymentModalComponent,
    CartAddSucessModalComponent,
    CartDeleteModalComponent,
    CartFlightDataComponent,
    ComboAirportComponent,
    ComboAirlineComponent,
    CartNewBtnComponent,
    CartCloseQuoteBtnComponent
  ],
  exports: [
    CartAddComponent,
    CartStepsComponent,
    CartDateComponent, 
    CartPassengerQtdComponent,
    ComboProductHotelsComponent,
    CartTotalBtnComponent,
    CartItemComponent,
    CartDeleteBtnComponent,
    CartShareBtnComponent,
    CartPrintBtnComponent,
    CartDownloadBtnComponent,
    CartPaymentBtnComponent,
    CartInvoiceBtnComponent,
    CartShareModalComponent,
    CartPaymentModalComponent,
    CartAddSucessModalComponent,
    CartDeleteModalComponent,
    CartNewBtnComponent,
    CartCloseQuoteBtnComponent
  ],
  entryComponents: [
    CartShareModalComponent,
    CartPaymentModalComponent,
    CartAddSucessModalComponent,
    CartDeleteModalComponent
  ],
  imports: [  
    PaymentModule, 
    CommonModule, 
    PartialModule,
    FormsModule,
    FormModule,
    MatCardModule,
    ReactiveFormsModule,
    PipeModule,
    DirectiveModule  
  ]
})
export class CartModule { }
