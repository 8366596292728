<div class="form-title">
    <h3>
      {{form.title}}
    </h3> 
    <div class="icons">
      <div class="item item-reload" *ngIf="isReload">
        <i class="material-icons icon-reload" (click)="reload.emit()">refresh</i>  
      </div>
      <div class="item item-close">
        <i class="material-icons" (click)="form.dialog.close()">close</i> 
      </div>
    </div>
</div>
  
  