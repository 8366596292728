import { Router, NavigationStart, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit,AfterViewInit {

  @ViewChild("drawer") drawer:MatDrawer;
  public isLogin = false;   
  @ViewChild("content") content:ElementRef;
  
  constructor(
    private router: Router,
    private menu: MenuService
  ) { }
  

  /**
   * 
   * Verifica se a página é a LOGIN
   * 
   * @param event 
   * 
   */
  setIsLogin(event){ 
    
    this.isLogin = event.url == "/login" ? true : false;  

  }
  /**
   * 
   * Events
   * 
   */
  onEvents(){
    
    this.router.events.forEach((event: NavigationEvent) => {
          
      if(event instanceof NavigationStart){
        
      }
      if(event instanceof NavigationEnd) {
        this.setIsLogin(event); 

        if(this.isLogin){
          this.drawer.close(); 
        } 

      }
    
    });
    this.menu.openMenuEmitter.subscribe(response => {

      this.drawer.close();
      
      setTimeout(() => {
        this.drawer.mode = "side";
        this.drawer.toggle();  
        if(typeof(this.content) != "undefined"){ 
          this.content.nativeElement.style.marginLeft = "230px"; 
        }
      });


    });

    
  }
  /**
   * 
   * On Resize
   * 
   * @param event 
   * 
   */
  onResize(event) {
    
    let width = event.target.innerWidth;

    if(width < 960){

      this.drawer.close();

    }else{

      this.drawer.open();

    }

  }
  /***
   * 
   * Inicializa as funções
   * 
   */
  ngOnInit() {
    
    this.onEvents();
   
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngAfterViewInit(): void {
    
    setTimeout(() => { 

      if(typeof(this.drawer) != "undefined"){

        let w = window.innerWidth;

        this.drawer.mode = "side"; // push - side - over

        if(w > 960 && !this.isLogin){
          this.drawer.toggle();
         }

      }

    },500);
       
  }

}
