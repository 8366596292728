import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ){ }

  /**
   * 
   * Delete item
   * 
   * 
   */
  delete(){
    
    if(typeof(this.data.dataItem.fnCallback) != "undefined"){
      this.data.dataItem.fnCallback(false,this.data.self);
    }else{
      this.data.self.delete(this.data);
    }  
    this.close();
    
  }
  /**
   * 
   * Close modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
  }

}
