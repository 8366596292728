<div class="topo topo-layout">
  <div class="item">
    <div class="btn-menu btn-back" (click)="toggle()">
      <i class="material-icons">
        {{icon}}
      </i>
    </div>  
  </div>
  <div class="item">  
    <div class="actions">
      <cart-total-btn></cart-total-btn>
      <user-profile></user-profile>    
      <notification-icon></notification-icon>  
    </div>
  </div>
</div>
