import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'form-toolbar',
  templateUrl: './form-toolbar.component.html',
  styleUrls: ['./form-toolbar.component.scss']
})
export class FormToolbarComponent implements OnInit {

  @Input("form") form:any;
  @Input("create") create = true;

  constructor() { }
  
  /**
   * 
   * Salva os dados
   * 
   */
  save(close=false,resetForm=false){

    this.form.save(close,resetForm);

  }
  /**
   * 
   * Fecha o Modal
   * 
   */
  close(){

    this.form.dialog.close();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){

  }

}
