<section class="modulo-loader {{classCustom}}">
    <div class="loader-wrap">
      <div class="content">
        <figure class="animated infinite pulse">
          <img src="/assets/logo/simbolo-2.svg" width="30px" height="30px" alt="Digital Movement - Agência Digital" />
        </figure>  
        <span class="message" *ngIf="message !== ''">{{message}}</span> 
      </div>  
    </div>
    <div class="overlay"></div> 
</section>
    