<div class="menu-default">
  <div class="header">
    <logo></logo>
  </div> 
  <ul class="nav-items"> 
    <li class="nav-item">
      <a routerLink="/" title="Home" class="nav-link">
        <span class="material-icons">home</span>
        Home
      </a>
    </li>  
    <li class="nav-item nav-expand" [hidden]="!acessoAdmin">
      <a href="javascript::void();" title="Cadastros" class="nav-link nav-expand-link">
        <span class="center">
          <i class="material-icons">edit</i>
          Cadastros
        </span>
      </a>
      <ul class="nav-items nav-expand-content">
        <li class="nav-link nav-link-title">
          <a href="javascript::void();" title="Cadastros">
            Cadastros
          </a>
        </li>
        <li class="nav-item">  
          <a class="nav-link" routerLink="/register/users" title="Usuários">
            <span class="material-icons"> 
              group
            </span>
            Usuários
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item nav-expand">
      <a href="javascript::void();" title="Vendas" class="nav-link nav-expand-link">
        <span class="center">
        <i class="material-icons">monetization_on</i>
        Vendas
        </span>
      </a>
      <ul class="nav-items nav-expand-content">
        <li class="nav-link nav-link-title">
          <a href="javascript::void();" title="Vendas">
            Vendas
          </a>
        </li>
        <li class="nav-item">  
          <a class="nav-link" routerLink="/sale/orders" title="Minhas Vendas">
            <span class="material-icons"> 
              shopping_basket
            </span>
            Minhas Vendas
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/sale/products" title="Produtos">
            <span class="material-icons">
              add_shopping_cart
            </span>
            Produtos
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/sale/payment-link" title="Link de Pagamento">
            <span class="material-icons">
              paid
            </span>
            Link de Pagamento
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item">  
      <a routerLink="/dashboard" title="Dashboard" class="nav-link">
        <span class="material-icons">insights</span>
        Dashboard
      </a>
    </li> 
  </ul>  
</div>
