import * as forms from '@angular/forms';
import { Component, OnInit, Input, forwardRef,Renderer2, ElementRef, AfterViewInit, ViewChild, EventEmitter, OnChanges, Output } from '@angular/core';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { DefaultValueAccessor, FormControl } from '@angular/forms';
import * as moment from 'moment';

declare var $:any;

const VALUE_ACCESSOR = {
  provide: forms.NG_VALUE_ACCESSOR, 
  useExisting: forwardRef(() => DmInputComponent),
  multi: true,
};

@Component({
  selector: 'dm-input',
  templateUrl: './dm-input.component.html',
  styleUrls: ['./dm-input.component.scss'], 
  providers: [
    VALUE_ACCESSOR
  ]
})
export class DmInputComponent extends DefaultValueAccessor implements OnInit,AfterViewInit,OnChanges {
  
  @Input("classCustom") classCustom = "";
  @Input("setMask") setMask         = null;  
  @Input("ngModel") value           = null;
  @Input("type") type               = "text";
  @Input("label") label             = null;
  @Input("rows") rows               = 5;
  @Input("name") name               = "name";
  @Input("max") max                 = null;
  @Input("placeholder") placeholder = "";
  @Input("disabled") disabled       = false;
  @ViewChild("datepicker") datepicker:ElementRef;
  @Input("info") info        = null;
  public setValueMoney = new EventEmitter();
  public _datepicker         = new FormControl();  
  @ViewChild("picker") picker:MatDatepicker<any>;
  public _date = null;
  public typeInput = "password";
  public openDate = false;
  @Input("minDate") minDate  = null;
  @Input("maxDate") maxDate  = null; 
  @Output("changeValue") changeValue = new EventEmitter();
  @Input("changeMonth") changeMonth  = false;
  @Input("changeYear") changeYear  = false;

  constructor(
    private renderer2: Renderer2, 
    private el: ElementRef 
  ) { 
      
    super(renderer2,el,false);
    
  }
  /**
   * 
   * Change value
   * 
   */
  onChangeValue(){
     
    super.writeValue(this.value);
    this.onChange(this.value); 
    this.changeValue.emit(this.value); 
  
  }
  /**
   * 
   * Mask Change
   * 
   * @param value 
   * 
   */
  maskChange(value){
    super.writeValue(value); 
    this.onChange(value);   
  }
  formatDate(data: MatDatepickerInputEvent<Date>){
    
    let date = new Date(data.value);

    let dia =  date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
    let mes:any = date.getMonth()+1;
    mes = mes < 10 ? "0"+mes : mes;
    let ano = date.getFullYear();

    this.value = dia+"-"+mes+"-"+ano;
    
    this.onChangeValue();

  }
  /**
   * 
   * 
   * 
   */
  configDatePicker(){

    let self = this;

    $(this.datepicker.nativeElement).datepicker({
      buttonImageOnly: false,
      dateFormat: "dd/mm/yy",   
      minDate: this.minDate === "false" || this.minDate === false ? null : new Date(),  
      changeMonth: this.changeMonth,
      changeYear: this.changeYear, 
      monthNamesShort: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
      dayNamesMin: ['Dom','Seg','Ter','Qua','Qui','Sex','Sab'],
      monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
      onSelect: function(value){
        
        self.value = value;
        self.onChangeValue();

      },
      onClose: function(value){

        self.openDate = false;
        

      },
      showOn: function(){

       

      }
    });

    if(this.minDate != null && this.minDate != "null" && this.minDate != "false" && this.minDate != false){
      $(this.datepicker.nativeElement).datepicker("option","minDate",this.minDate);
    }
    if(this.maxDate != null){
      $(this.datepicker.nativeElement).datepicker("option","maxDate",this.maxDate);
    }

  }
  /**
   * 
   * Open calendar
   * 
   */
  openCalendar(){

    try{
      
      //if(!this.openDate){
      
        $(this.datepicker.nativeElement).datepicker("show");
        this.openDate = true;
      
      //}
    
    }catch(e){

    
    }

  }
  setValues(){

    if(this.type == "money"){
      this.setValueMoney.emit(this.value);
    }

  }
  onDate(event: any): void {
    
    this.formatDate(event.target.value);   
    this.onChangeValue();    

  }
  /**
   * 
   * Formata o valor
   * 
   */
  formatDatepicker(){  

    let data = this.value.split("-");
    let mes  = parseInt(data[1]);

    if(!isNaN(mes)){

      mes = mes-1;
      this._date = new Date(data[2],mes,data[0]);
    
    }  

  }
  /**
   * 
   * Open Picker
   * 
   */
  _openPicker(){

    if(this.picker.opened){
      this.picker.close();
    }else{
      this.picker.open();
    }

  }
  /**
   * 
   * Set type
   * 
   */
   setType(){ 

    this.typeInput = this.typeInput == "password" ? "text" : "password";

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
    this.setValues();
          
  }
  /**
   * 
   * Inicializa após carregar a VIEW
   * 
   * 
   */
  ngAfterViewInit(): void {
    
    if(this.type == "date"){
      setTimeout(() => {
        this.configDatePicker();
      },1000);
    }

    
  }
  /**
   * 
   * Init
   * 
   * 
   */
   ngOnChanges(changes:any): void {
    
    if(changes.minDate){ 
      if(typeof(this.datepicker) != "undefined"){
        setTimeout(() => { 
          if(changes.minDate.currentValue !== "" && changes.minDate.currentValue !== "null" && changes.minDate.currentValue !== null){
            let d  = moment(changes.minDate.currentValue,"DD/MM/YYYY",true); 
            if(d.isValid()){
              $(this.datepicker.nativeElement).datepicker("option","minDate",changes.minDate.currentValue);
            }
          }
        },500);
      }
    }
    if(changes.maxDate){
      
      if(typeof(this.datepicker) != "undefined"){
        setTimeout(() => {
          if(changes.maxDate.currentValue !== "" && changes.maxDate.currentValue !== "null" && changes.maxDate.currentValue !== null){
            let d  = moment(changes.maxDate.currentValue,"DD/MM/YYYY",true); 
            if(d.isValid()){ 
              $(this.datepicker.nativeElement).datepicker("option","maxDate",changes.maxDate.currentValue);
            }
          }
        },500);
      }

    }

  }

}
