import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenu, MatMenuContent, MatMenuTrigger } from '@angular/material/menu';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss']
})
export class NotificationIconComponent implements OnInit {

  @Input("notifications") notifications = [];
  public loader = false;
  public response = {
    data: "",
    status: null
  }
  @ViewChild("menu") menu: MatMenuTrigger;

  constructor(
    private api: ApiService,
    private app: AppService
  ){}

  /**
   * 
   * Open menu
   * 
   */
  _open(){

    this.menu.openMenu();  

  }
  /**
   * 
   * Get notificações
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.notification().partner().subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.notifications   = response.data;
          this.response.status = 1;

        }else{

          let error     = this.api.formatError(response);
          this.response = {
            data: error.message,
            status: 500
          }

        }

      },(response) => {

        this.loader   = false;
        let error     = this.api.formatError(response);
        this.response = {
          data: error.message,
          status: 500
        }

      });


    }catch(e){

      this.loader   = false;
      this.response = {
        data: e.message,
        status: 500
      }

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.getData();
  }

}
