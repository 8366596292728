<div class="notification-icon">
    <div class="header">
        <div class="icon" [matMenuTriggerFor]="menu" (click)="getData()">
            <span class="qtd">{{notifications.length}}</span>
            <i class="material-icons">
                notifications
            </i>
        </div>    
    </div>
    <mat-menu class="content-menu" #menu>
        <div class="content" style="position:relative;"> 
            <div class="notification-list" *ngIf="response.status == 1">   
                <div class="list">
                    <div class="item" *ngFor="let i of notifications">
                        <h3>{{i.title}}</h3> 
                        <article [innerHtml]="i.text | safeHtml" *ngIf="i.text != null && i.text != ''"></article>
                        <a [href]="i.link" *ngIf="i.link_active" target="_blank"> 
                            Clique aqui
                        </a>    
                    </div>    
                </div>    
            </div>
            <div class="notification-list" *ngIf="response.status == 500">
                <div class="icon-close">
                    <i class="material-icons">
                        close
                    </i>
                </div>    
                <p class="message-empty">Não foi possível carregar as notificações.</p>
            </div>
            <loader classCustom="absolute" [hidden]="!loader"></loader>
        </div>
    </mat-menu>   
</div>    